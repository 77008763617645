
import { defineComponent } from 'vue';
import Screen from '@/components/layout/Screen.vue';

export default defineComponent({
    name: 'import-errors-screen',
    components: {
        Screen,
    },
});
